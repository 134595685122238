import React from "react"
import Helmet from "react-helmet"

const StructuredData = ({
  data: {
    fullName, 
    profile_pic, 
    broker_rating, 
    review_count,
    broker_details: {
      suburb
    }
  }
}) => {
  // const data = {
  //   "@context":"http://schema.org",
  //   "@type":"LocalBusiness",
  //   "name":`${fullName} - Mortgage Broker ${suburb}`,
  //   "image":profile_pic,
  //   "aggregateRating":
  //   {
  //     "@type":"AggregateRating",
  //     "ratingValue":broker_rating,
  //     "ratingCount":review_count
  //   }
  // }
  const data = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "name": `${fullName} - Mortgage Broker ${suburb}`,
    "image": profile_pic
  };
  
  const aggregateRating = {
    "@type": "AggregateRating",
    "ratingValue": broker_rating,
    "ratingCount": review_count
  };
  
  if (review_count != 0 && broker_rating != 0 ) {
    data.aggregateRating = aggregateRating;
  }

  return (
    <Helmet>
      <script type="application/ld+json">
      {
        JSON.stringify(data)
      }
      </script>
    </Helmet>
  )
}

export default StructuredData