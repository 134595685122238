import React, { Component } from "react";
import Axios from "axios";
import { graphql } from "gatsby";
import cx from 'classnames';
import Layout from "../components/layout";
import Breadcrumbs from "../components/breadcrumbs";
import BrokerProfileInfo from "../components/brokerProfileInfo";
import BrokerProfileStats from "../components/brokerProfileStats";
import BrokerProfileDetails from "../components/brokerProfileDetails";
import BrokerProfileAbout from "../components/brokerProfileAbout";
import BrokerProfileTabs from "../components/brokerProfileTabs";
import BrokerProfileQualifications from "../components/brokerProfileQualifications";
import SaveInfoLater from "../components/saveInfoLater";
// import FooterBottomContact from "../components/footerBottomContact";
import Cookies from 'universal-cookie';
import CompareDealBottomBar from "../components/deals/compareDealBottomBar";
import CustomHelmet from '../components/customHelmet'
import StructuredData from "../components/structuredData";
import DealComparison from "../components/deals/dealComparison";
import { removeCookie, log, host } from '../helpers/commons'
import allKenticoCloudItemPageBrokerprofile from "../../data/kentico/allKenticoCloudItemPageBrokerprofile";

class BrokerProfile extends Component {
  constructor() {
    super();
    this.state = {
      deaLSort: '1',
      DealsSelectedForCompare: [],
      openCompareDealsModalVal: false
    }
  }

  componentDidMount() {
    /**
     * This section of code is not loading data for a broker. 
     * Commented to check
     */
    // let brokerId = '';
    // let current_broker_id = String(this.props.data.hashchingBroker.broker_id);
    // Axios.get(host + '/gatsby-api/all-active-brokers-id?brokers=realtime').catch((error) => {
    //   console.error(error);
    // }).then((response) => {
    //   if (response) {
    //     brokerId = response.data.brokersId;
    //     if (brokerId !== undefined) {
    //       var split_str = brokerId.split(",");
    //       //console.log(split_str.indexOf(current_broker_id));
    //       //console.log(split_str);
    //       //console.log(current_broker_id);
    //       if (split_str.indexOf(current_broker_id) === -1) {
    //         window.location.replace("/mortgage-broker-finder");
    //       }
    //     }
    //   } else {
    //     //console.log('no response');
    //   }
    // })
    /** Commented code ends */
  }

  compareDeals(value) {
    var arrDeals = this.state.DealsSelectedForCompare;
    var index = arrDeals.indexOf(value.value)
    if (!value.checked && index !== -1) {
      arrDeals.splice(index, 1);
    }
    else if (value.checked && !arrDeals.includes(value.value)) {
      arrDeals.push(value.value);
    }

    this.setState({
      DealsSelectedForCompare: arrDeals
    });
  }
  clearComparedDeals(value) {
    this.setState({
      DealsSelectedForCompare: []
    });
  }
  openCompareDealsModal(value) {
    this.setState({
      openCompareDealsModalVal: value
    });
  }
  render() {
    let params = {}, queries, temp, i, l, queryString, renderElement, seo_canonicalurl;
    const data = this.props.data.hashchingBroker;
    const deals = this.props.data.allHashchingDeal.edges;
    //const pagebrokerprofile = this.props.data.allKenticoCloudItemPageBrokerprofile.edges[0].node.elements;
    const pagebrokerprofile = allKenticoCloudItemPageBrokerprofile.edges[0].node.elements;
    console.log(pagebrokerprofile)
    const seo_title = data.fullName + ' - Mortgage Broker ' + data.location;
    const seo_description = data.fullName + ', is a HFG Marketplace Mortgage Broker servicing ' + data.location + '. Contact for pre-negotiated home loans with deals from mulitple lenders. ';


    seo_canonicalurl = 'https://www.hashching.com.au' + data.profile_view;

    queryString = this.props.location.search;
    queryString = decodeURIComponent(queryString.substring(queryString.indexOf('?') + 1));
    queries = queryString.split("&");

    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split('=');
      params[temp[0]] = temp[1];
    }
    let ogImage = '';
    if (params.s) {
      ogImage = 'https://s3-ap-southeast-2.amazonaws.com/hashching/uploads/review-share/review-' + params.s + '.png';
    }

    let breadcrumbs = [
      {
        name: 'Mortgage Broker Finder',
        link: '/mortgage-broker-finder'
      },
      {
        name: data.stateCode,
        link: '/mortgage-broker-finder' // TODO: change to the right url
      },
      {
        name: data.fullName,
        link: data.profile_view
      }
    ];
    if (data.broker_details.suburb) {
      breadcrumbs.splice(2, 0, {
        name: data.broker_details.suburb,
        link: '/mortgage-broker-finder' // TODO: change to the right url
      },)
    }
    const styles = {
      backgroundColor: "#252b42",
      borderTop: "1px solid #d0d7dd",
      borderBottom: "1px solid #d0d7dd",
      paddingTop: "150px"
    };


    const cookies = new Cookies();
    cookies.set('broker', data.broker_id, { path: '/' })
    cookies.set('pCode', data.postcode, { path: '/' });
    cookies.set('stateCode', data.stateCode, { path: '/' });
    cookies.set('suburb', data.broker_details.suburb, { path: '/' });
    removeCookie('SaveName');
    removeCookie('SaveEmail');

    return (
      <section className={'brokrProfilePageMain'}>
        <Layout>
          <CustomHelmet>
            <title>{seo_title}</title>
            <meta name="description" content={seo_description} />
            <meta name="keywords" content={'Mortgage Broker'} />
            <meta property="og:description" content={seo_description} />
            <meta property="og:title" content={seo_title} />
            <meta property="og:url" content={seo_canonicalurl} />
            <meta name="twitter:description" content={seo_description} />
            <meta name="twitter:title" content={seo_title} />
            <link rel="canonical" href={seo_canonicalurl} />
            <meta name="image" property="og:image" content={ogImage} />

          </CustomHelmet>

          <section style={styles}>
            {/* <Breadcrumbs breadcrumbs={breadcrumbs} /> */}
            <section>
              <div className={'container'}>
                <div className={cx("mb-3")}>
                  <a className={" btn btn-lnk"} href="/mortgage-broker-finder">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0" />
                    </svg>
                    Back to List
                  </a>
                </div>
                <div className={'row'}>
                  <div className={'col-xl-4 col-lg-5'}>
                    <BrokerProfileInfo data={data} />
                    <BrokerProfileStats data={data} />
                    <BrokerProfileDetails data={data} />
                  </div>
                  <div className={'col-xl-8 col-lg-7'}>
                    <BrokerProfileAbout data={data} />
                    <BrokerProfileTabs data={data} deals={deals} compareDeals={this.compareDeals.bind(this)} DealsSelectedForCompare={this.state.DealsSelectedForCompare} oBreadcrumbs={breadcrumbs} />
                    <BrokerProfileQualifications data={data} />
                    <SaveInfoLater type={'broker'} type_id={data.broker_id} data_info={data} />
                  </div>
                </div>
              </div>
              <div className={'container'}>
                <div className={'row'}>
                  <div className={'col-12'}>
                    <div className={'fine-print'}>
                      <p className={'fine-print-text'}>

                      </p>
                      <p className={'fine-print-text'}>

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </section>

          {/* <FooterBottomContact content={'asd'} layout={'full'} inPage={'book-appointment'}></FooterBottomContact>  */}
          <StructuredData data={data} />
          <DealComparison openCompareDealsModal={this.openCompareDealsModal.bind(this)} openCompareDealsModalVal={this.state.openCompareDealsModalVal} DealsSelectedForCompare={this.state.DealsSelectedForCompare} />
          <CompareDealBottomBar clearComparedDeals={this.clearComparedDeals.bind(this)} openCompareDealsModal={this.openCompareDealsModal.bind(this)} DealsSelectedForCompare={this.state.DealsSelectedForCompare} />
        </Layout>
      </section>
    )
  }
}

export default BrokerProfile;

export const pageQuery = graphql`
  query($id: String!) {
    hashchingBroker(id: { eq: $id }) {
      broker_id
      user_id
      firstName
      lastName
      fullName
      location
      profile_pic
      deals_count
      review_count
      broker_rating
      languages
      premium
      postcode
      state
      stateCode
      profile_view
      specifications
      broker_details {
        business_name
        business_abn
        acl_crn
        avgResponse
        profile_summary
        suburb
        broker_postcode
        lat
        lng
        about
        leadReviews {
          id
          feedback
          rating
          reviewSubmittedDate
          leadReview {
            first_name
            last_name
          }
        }
        deals_detail {
          id
        }
        brokerQA {
          count
          questions {
            id
            currentUserID
            user_id
            decodedQ
            date
            slug
            first_name
            last_name
            profile_pic
            QuestionUserprofile_view
            postcode
            state
            status
            reply {
              id
              userdata {
                profile_view
                reply_user_id
                reply_user_profile_pic
                reply_user_first_name
                reply_user_last_name
                reply_date
                reply
              }
            }
          }
        }
        qualifications
        association
        brokerGoogleBusinessname
        achievements {
          title
          subtitle
        }
       
      }
    }

    allHashchingDeal
    {
      edges {
        node {
          id
          slug
          deal_id
          interest_rate
          interest_type
          repayment_type
          comparison_interest_rate
          loan_to_value_ratio
          application_fees
          ongoinFees
          ongoing_fee_type
          allNewFeatures
          deal_type_id
          minimum_borrow_amount
          meta_description
          seo_title
          focus_keyword
          facebook_title
          facebook_description
          canonical_url                    
          monthly_repayments
        }
      }
    }
  }
`